import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const DeployReact = () => (
  <Layout>
    <SEO
      title="Deploy a React App to Production"
      description="Step by step how to guide with images on how to host your React app quickly. Deploy your react app to production on your own custom hosting plan and domain"
    />
    <h1>Deploying a react app to your custom domain</h1>

    <p>
      When I first started using react, I looked around out of curiosity to see
      how I would publish my project but found nothing. Every tutorial was
      saying how easy it is to deploy from code sandbox to Netlify. This is not
      what I wanted and it was frustrating, it was like every writer just copies
      from each other.
    </p>
    <p>
      You are probably aware of the free hosting site Netlify and how easy it is
      to deploy from code sandbox to Netlify. This is fine but what if you
      already have your own hosting plan with a custom domain?
    </p>
    <p>
      When I finished my project, I put some time into figuring this out and it
      was quite easy. I’ll layout the steps ahead.
    </p>
    <ul>
      <li>For hosting I use SiteGround</li>
      <li>For domain names I use Namecheap</li>
      <li>If you haven’t already, please install npm and node</li>
    </ul>

    <h2>This guide is written in different 3 sections</h2>
    <p>
      <strong>Quick Steps</strong> – Minimal guide if you are familiar with web
      development (advanced)
    </p>
    <p>
      <strong>Full Guide</strong> – Step by Step with minimal description for
      speed (beginner/ Intermediate)
    </p>
    <p>
      <strong>Full Guide Further Notes</strong> – Gives a better understanding
      (beginner/ Intermediate)
    </p>
    <p>
      <i>
        Some of the links in this article may be affiliate links, which means I
        may receive a small commission at no additional cost to you if you
        decide to purchase something.
      </i>
    </p>
    <h2>Quick Steps</h2>
    <ol>
      <li>Export your app</li>
      <li>In terminal; run npm install and npm run build</li>
      <li>Upload files to web host</li>
    </ol>
    <h2>Full Guide</h2>
    <p>
      Sign into CodeSandbox and create a React Sandbox. I use the React by
      CodeSandbox Team template.{" "}
      <strong>Ignore if you don't use CodeSandbox</strong>.
    </p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/sHlk63B.png"
        alt="create react app"
        description="create react app codesandbox"
      />
    </div>

    <p>
      <strong>Fork</strong> the sandbox, I called mine test.
    </p>
    <p>
      In the public folder, create a file called <strong>htaccess</strong> and
      paste in the following code.
    </p>

    <pre>
      {`
RewriteEngine On
RewriteCond %{REQUEST_FILENAME} !-f
RewriteRule ^([^.]+)$ $1.html [NC,L]

RewriteEngine on
RewriteCond %{THE_REQUEST} /([^.]+)\.html [NC]
RewriteRule ^ /%1 [NC,L,R]

RewriteCond %{REQUEST_FILENAME}.html -f
RewriteRule ^ %{REQUEST_URI}.html [NC,L]
          `}
    </pre>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/uSkGEoW.png"
        alt="htaccess react contents"
      />
    </div>

    <p>
      In <strong>package.json</strong> add a new line for homepage as follows;
    </p>

    <pre>
      {`
"homepage": ".",
          `}
    </pre>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/zVgEcae.png"
        alt="package.json react"
      />
    </div>
    <p>Export to ZIP in CodeSandbox</p>
    <p>Move to a folder on your computer</p>
    <p>Unzip the file, here’s my structure</p>
    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/2IArkwy.png"
        alt="react export zip"
      />
    </div>

    <p>
      In the{" "}
      <a
        href="https://www.macworld.co.uk/how-to/mac-software/how-use-terminal-on-mac-3608274/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        terminal
      </a>{" "}
      <strong>cd</strong> (change directory) to the directory then run the
      following;
    </p>

    <pre>
      {`
npm i
          `}
    </pre>
    <p>When it is finished run;</p>
    <pre>
      {`
npm run build
          `}
    </pre>
    <p>You will see a new build folder in the project directory</p>
    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/U71GhP5.png"
        alt="react npm run build"
      />
    </div>
    <p>In the project folder Compress the build folder only</p>
    <p>Login to Siteground then go over to CPanel then File Manager</p>
    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/rdTb6fm.png"
        alt="React siteground cpanel file manager"
      />
    </div>
    <p>
      Find the root of your domain, this should be under the public_html folder.
      I host multiple sites on my account so mine is one file deeper, under 
      <strong>/public_html/campbelldatascience.com</strong>.
    </p>
    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/H2nOX2x.png"
        alt="cpanel react deploy directory"
      />
    </div>
    <p>
      In this example we will create a folder called test that you want the page
      to be hosted on.
    </p>
    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/uSsMAeQ.png"
        alt="cpanel react app directory"
      />
    </div>
    <p>Click upload and set the permissions to 755 as follows</p>
    <p>Navigate to and upload the build zip file</p>
    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/aydHDEk.png"
        alt="cpanel react app permission"
      />
    </div>
    <p>Extract the files in the suggested folder</p>
    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/CM2Ltvi.png"
        alt="react cpanel extract folder"
      />
    </div>
    <p>
      Open the unzipped test folder, highlight the contents and move them up one
      folder level i.e. from{" "}
    </p>
    <p>
      <strong>/public_html/campbelldatascience.com/test/build</strong> to{" "}
    </p>
    <p>
      <strong>/public_html/campbelldatascience.com/test/</strong>.
    </p>
    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/s5GxD1B.png"
        alt="react cpanel build folder"
      />
    </div>
    <p>
      At this point, you can delete the empty build folder and the build zip
      file to tidy your directory.
    </p>
    <p>
      You may have guessed that this will be the directory of your app location.
      e.g. if I navigate to <strong>campbelldatascience.com/test/</strong> I
      should find the app I just made… <strong>Success!</strong>
    </p>
    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/g8XgMCl.png"
        alt="react app cpanel deployed"
      />
    </div>
    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/qTcQgpx.png"
        alt="react app deploy sub directory"
      />
    </div>

    <h2>Notes</h2>
    <ul>
      <li>
        the file is no longer there, I deleted the test folder so you cannot
        navigate to it.
      </li>
      <li>
        If you ignored the htaccess step, you might have to manually type out
        the whole route to your index file. e.g.
        https://yoursite.com/index.html. It depends on how your hosting is set
        up.
      </li>
    </ul>
    <p>Get in touch if you have any questions.</p>

    <h2>Full Guide further notes</h2>
    <p>
      <strong>
        Just in case you needed more of an explanation on some sections.
      </strong>
    </p>
    <p>
      If you are using CodeSandbox then the first step is to use the export your
      project. Your browser will download your project as a zip with the title
      of your sandbox.
    </p>
    <p>
      Move the zip file to somewhere on your computer, I have a project folder
      where I keep a backup of each version, as well as other locations like
      GitHub etc. The zip should be quite small. Unzip the folder.
    </p>
    <p>
      Open your terminal and navigate to the folder you just unzipped. In case
      you are new, I type “cd”, space then drag the folder into the terminal.
      You should see the folder location in there terminal then press enter. In
      the terminal, enter the following commands;
    </p>
    <p>
      “npm i” (short for  “npm install”) then enter. Wait for the terminal to
      finish what it is doing, it took my MacBook 3 minutes. You folder should
      have grown massively. 300MB for a small project is normal. This command
      install all of your dependencies and lets your app run outside of code
      sandbox where all of your dependencies were already installed. It reads
      your dependencies from the package.json file.
    </p>
    <p>
      “npm run build” then enter. This builds a folder containing a static
      version of your site. During development, your environment uses node. Node
      is a backend JavaScript runtime environment. This just means that it
      executes on a server rather than in the browser. Code that runs on the
      server is considered backend. The magic of react apps is that they can be
      static, meaning they don’t need a back end.
    </p>
    <h2>npm run build failure and errors</h2>
    <p>
      If you get any errors at this stage, fix them by following the error
      reports and prompts then use “npm run build” in the terminal again. Errors
      that aren’t picked up in code sandbox can be highlighted here.
    </p>
    <p>
      For example; I have a component called “Description.jsx” and a file
      containing a data variable called “description.js”. I noticed react
      doesn’t care if you use the extension js or jsx so I use the extensions
      with capitalisation/ lower case to differentiate between my data files and
      components. Also, react doesn’t need you to add the file extension when
      you import. When I tried to run build these things caused an error. The
      fix was to simply add the extension to the component import.
    </p>
    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/DckzIT8.png"
        alt="errors react deploy"
      />
    </div>
    <h2>npm run build successful</h2>
    <p>
      When run build is successful, the folder will be significantly smaller
      than when you ran npm install. A few megabytes probably.
    </p>
    <p>
      Here is where things will differ depending on your web hosting setup but
      the idea is the same. You need to upload the contents of your build folder
      to your desired directory on you web server.
    </p>
    <p>
      I use site ground as a web hosting platform, they use CPanel for browsing
      and managing files. CPanel is very popular so It’s likely you will be able
      to follow along. I’m going to be using Amazon S3 soon and process is a
      little different for them.
    </p>
    <p>
      login to your web host and navigate to the public root. It should be under
      public_html in the root.
    </p>
    <p>
      If you want the app to run in the root of your website then you want the
      folder contents in here e.g. <strong>www.example.com/</strong>  if you
      want your app in a subdirectory then you want the contents in that folder
      e.g. a folder called example will have the web address{" "}
      <strong>www.example.com/example/</strong> .
    </p>
    <p>
      Upload your zipped build folder and unzip it. Then highlight the contents
      in the build folder and move them to the folder above. Now you can delete
      the files you don’t need like the now empty build folder, the used zip and
      the DS_Store.
    </p>
    <p>
      Now you should make the contents of this folder available to the public, I
      use the following settings. Only you should have write access and anyone
      should be able to read and execute. Right click the folder and click to
      edit permissions.
    </p>
    <p>
      If you enter the address to your index.html in a browser, your app should
      display. Now try to enter just the path to your folder.
    </p>
    <p>
      If this part doesn’t work, create a new file called “.htaccess” with
      uni-code8 and set some rewrite rules. I like to using the code I mentioned
      above for my websites. It gives a clean url with no https:// and keeps the
      trailing forward slash.
    </p>
    <p>
      You can read more about htacess but it basically takes what is typed into
      the browser search and tidies it. for example without it if someone typed{" "}
      <strong>example.com</strong> they might not make it to your site{" "}
      <strong>www.example.com</strong> and htaccess fixes that. Note, when I
      work on my Gatsby js projects, I force https redirect using the host
      rather than use the htaccess file.
    </p>
    <p>Thanks for reading! I hope this worked for you.</p>
    <p>
      <i>
        Some of the links in this article may be affiliate links, which means I
        may receive a small commission at no additional cost to you if you
        decide to purchase something.
      </i>
    </p>

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default DeployReact
